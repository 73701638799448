import { FC, CSSProperties } from "react";
import classNames from "classnames";
import { gotoOAuthPortal } from "../../services/auth";
import styles from "./index.module.css";

interface IProps {
  theme: "light" | "dark";
  style?: CSSProperties;
}

const SigninButton: FC<IProps> = ({ theme, style }) => {
  return (
    <div
      className={classNames(styles["sign-in-button"], {
        [styles["sign-in-button-light"]]: theme === "light",
        [styles["sign-in-button-dark"]]: theme === "dark",
      })}
      style={style}
      onClick={() => {
          gotoOAuthPortal("login");
        }
      }
    >
      Sign In
    </div>
  );
};

export default SigninButton;
