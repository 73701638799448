import { FC, ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

interface IProps {
  theme: "light" | "dark";
  items: Array<{
    icon?: ReactNode;
    text: string;
    link?: string;
    onClick?: () => void;
  }>;
}

const Menu: FC<IProps> = ({ theme, items }) => {
  return (
    <div className={styles["menu"]}>
      {items.map((it, index) => {
        const className = classNames(styles["menu-item"], {
          [styles["menu-item-light"]]: theme === "light",
          [styles["menu-item-dark"]]: theme === "dark"
        })
        const content: ReactNode = (
          <>
            {it.icon}
            {it.text}
          </>
        );
        if (it.link) {
          const isIntervalLink = it.link.startsWith("/");
          return isIntervalLink ? (
            <Link key={index} to={it.link} className={className}>
              {content}
            </Link>
          ) : (
            <a
              key={index}
              href={it.link}
              // target="_blank"
              rel="noreferrer"
              className={className}
            >
              {content}
            </a>
          );
        } else {
          return (
            <div
              key={index}
              className={className}
              onClick={() => {
                if (it.onClick) {
                  it.onClick();
                } else {
                  console.warn(
                    `No onClick event for menu item with text of ${it.text}`
                  );
                }
              }}
            >
              {content}
            </div>
          );
        }
      })}
    </div>
  );
};

export default Menu;
