import { FC, useContext, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { InstanceContext } from "../../context/InstanceContext";
import OpenInNew from "./OpenInNew";
import { fetchMspInstanceDetailsAsync } from "../../services/instance";
import { Ring } from "@uiball/loaders";
import { launchMspPortal } from "../../context/AuthContext";
import styles from "./index.module.css";

interface IProps {
  theme: "light" | "dark";
}

const LauchPortalButton: FC<IProps> = ({ theme }) => {
  const { currentInstanceDetails, updateCurrentInstance } = useContext(InstanceContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  if (!currentInstanceDetails || pathname === "/specify-domain") return null;

  const className = classNames(styles["launch-portal-button"], {
    [styles["launch-portal-button-dark"]]: theme === "dark",
    [styles["launch-portal-button-light"]]: theme === "light",
  });

  async function handleClick() {
    if (!currentInstanceDetails) {
      console.error("Current Instance Details Doesn't Exist");
      return;
    }
    if (currentInstanceDetails.installed) {
      launchMspPortal(currentInstanceDetails.domain, "_blank")
      return;
    }
    if (loading) return;
    try {
      setLoading(true);
      const latestDetails = await fetchMspInstanceDetailsAsync(
        currentInstanceDetails.id
      );
      updateCurrentInstance(latestDetails); // update global context
      if (latestDetails.domain === "*") {
        navigate("/specify-domain");
        return;
      }
      if (!latestDetails.installed) {
        alert("You MSP portal is not ready yet. Please try again in a few minutes.");
        return;
      }
      launchMspPortal(latestDetails.domain, "_blank")
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  const iconColor = theme === "light" ? "#555" : "#dfdfdf";

  return (
    <div className={className} onClick={handleClick}>
      {loading ? (
        <Ring size={20} color={iconColor} />
      ) : (
        <OpenInNew color={iconColor} />
      )}
      <span>Launch My Portal</span>
    </div>
  );
};

export default LauchPortalButton;
