import { FC, useContext, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { checkoutAsync } from "../../services/checkout";
import PurchasePlanCard from "../../components/PlanCard/PurchasePlanCard";
import { AuthContext } from "../../context/AuthContext";
import { gotoOAuthPortal } from "../../services/auth";
import { UIConfigContext } from "../../context/UIConfigContext";
import { IPlanConfig } from "../../data/interface";
import {
  IFreePlan,
  IPaidPlan,
} from "../../components/PlanCard/PurchasePlanCard/interface";
import Title from "../../components/Title";
import { SS_KEY } from "../../constants";
import FeatureComparisonTable from "../../components/FeatureComparisonTable";
import SeatsInfo from './SeatsInfo';
import "./index.css";

type Interval = "monthly" | "yearly";

const Plans: FC = () => {
  /* state */

  const [searchParams, setSearchParams] = useSearchParams();

  const { config } = useContext(UIConfigContext);

  const authInfo = useContext(AuthContext);

  const [loading, setLoading] = useState<
    false | { type: "personal" | "business" }
  >(false);

  /* computed values */

  const interval: Interval = (searchParams.get("interval") as Interval) || "yearly"

  const plans: {
    free: IFreePlan;
    personal: Record<"monthly" | "yearly", IPaidPlan>;
    business: Record<"monthly" | "yearly", IPaidPlan>;
  } = useMemo(() => {
    const freePlanConfig = config?.plans.find(
      (it) => it.id === "free"
    ) as IPlanConfig;
    const personalPlanConfig = config?.plans.find(
      (it) => it.id === "personal"
    ) as IPlanConfig;
    const businessPlanConfig = config?.plans.find(
      (it) => it.id === "business"
    ) as IPlanConfig;
    function parseConfigToPlan(
      config: IPlanConfig,
      interval: "monthly" | "yearly"
    ): IPaidPlan {
      const configPayment = config[interval];
      return {
        payment: {
          type: config.id as "personal" | "business",
          interval: interval,
          price: configPayment.price,
          monthly_price: configPayment.monthly_price,
          trial: configPayment.trial_period
            ? {
                price: configPayment.trial_price,
                period: configPayment.trial_period,
              }
            : undefined,
        },
        features: config.key_features_desc,
      };
    }
    return {
      free: {
        payment: {
          type: "free",
          price: "$0",
        },
        features: freePlanConfig.key_features_desc,
      },
      personal: {
        monthly: parseConfigToPlan(personalPlanConfig, "monthly"),
        yearly: parseConfigToPlan(personalPlanConfig, "yearly"),
      },
      business: {
        monthly: parseConfigToPlan(businessPlanConfig, "monthly"),
        yearly: parseConfigToPlan(businessPlanConfig, "yearly"),
      },
    };
  }, [config]);

  /* functions */

  async function handlePurchase(type: "personal" | "business") {
    if (!authInfo.id_token) {
      const selected_at = Date.now();
      sessionStorage.setItem(SS_KEY.SELECTED_PLAN_BEFORE_SIGNIN, JSON.stringify({ type, interval, selected_at }))
      gotoOAuthPortal("signup");
      return;
    }
    if (loading) return;
    try {
      setLoading({ type });
      const { url } = await checkoutAsync(type, interval);
      window.location.replace(url);
    } catch (err) {
      console.error(err);
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  /* view */

  return (
    <>
      <main>
        <section className="plans">
          <Title
            size="large"
            style={{ marginBottom: 96, textAlign: "center" }}
          >
            Choose the Plan That's Right for You
          </Title>
          <div className="plan-cards">
            <PurchasePlanCard
              plan={plans.free}
              style={{
                flex: 2,
                marginBottom: 40,
                boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.14)",
              }}
              onSelect={() => {
                window.location.assign("https://my.firewalla.com");
              }}
              interval={interval}
            />
            <div className="paid">
              <div className="tabs-title">
                <div
                  className={interval === "monthly" ? "active" : undefined}
                  onClick={() => {
                    setSearchParams({ interval: "monthly" })
                  }}
                >
                  Monthly
                </div>
                <div
                  className={interval === "yearly" ? "active" : undefined}
                  onClick={() => {
                    setSearchParams({ interval: "yearly" })
                  }}
                >
                  Yearly
                </div>
              </div>
              <div className="tabs-content">
                {interval === "monthly" && (
                  <div>
                    <PurchasePlanCard
                      loading={loading && loading.type === "personal"}
                      plan={plans.personal.monthly}
                      style={{ flex: 1 }}
                      onSelect={() => {
                        handlePurchase("personal");
                      }}
                    />
                    <PurchasePlanCard
                      loading={loading && loading.type === "business"}
                      plan={plans.business.monthly}
                      style={{ flex: 1 }}
                      onSelect={() => {
                        handlePurchase("business");
                      }}
                    />
                  </div>
                )}
                {interval === "yearly" && (
                  <div>
                    <PurchasePlanCard
                      loading={loading && loading.type === "personal"}
                      plan={plans.personal.yearly}
                      style={{ flex: 1 }}
                      onSelect={() => {
                        handlePurchase("personal");
                      }}
                    />
                    <PurchasePlanCard
                      loading={loading && loading.type === "business"}
                      plan={plans.business.yearly}
                      style={{ flex: 1 }}
                      onSelect={() => {
                        handlePurchase("business");
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <SeatsInfo />
        <section className="feature-comparison">
          <Title
            size="medium"
            style={{ marginBottom: 48, textAlign: "center" }}
          >
            Compare Features
          </Title>
          <FeatureComparisonTable />
        </section>
      </main>
    </>
  );
};

export default Plans;
