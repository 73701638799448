import { FC, CSSProperties } from "react";
import { IPurchasePlan } from "./interface";
import CheckoutButton from "../../CheckoutButton";
import styles from "./index.module.css";

interface IProps {
  plan: IPurchasePlan;
  loading?: boolean;
  style?: CSSProperties;
  interval?: "monthly" | "yearly";
  onSelect: () => void;
}

const PurchasePlanCard: FC<IProps> = ({
  plan,
  loading = false,
  style,
  interval,
  onSelect,
}) => {
  const { payment, features } = plan;
  const { price, type } = payment;
  return (
    <div className={styles["plan-card"]} style={style}>
      <header>
        <h3>
          {type === "free"
            ? "Free"
            : type === "personal"
              ? "Professional"
              : "Business"}
        </h3>
        <h3>{type === "free" ? price : payment?.trial?.price || price}</h3>
        {type !== "free" && payment.trial && (
          <>
            <div>for a {payment.trial.period} trial</div>
            <div style={{ color: "#8E8E8E", transform: "translateY(-4px)" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <span
                  style={{
                    color: type === "personal" ? "#027BFF" : "#9D4FF0",
                    fontWeight: 700,
                  }}
                >
                  {price}
                </span>
                <span>
                  {(function () {
                    let text = `per ${payment.interval === "monthly" ? "month" : "year"
                      } after trial`;
                    return text;
                  })()}
                </span>
              </div>
              {payment.interval !== "monthly" && payment.monthly_price && (
                <div style={{ marginTop: 8 }}>
                  {`(${payment.monthly_price} ${payment.type === "personal" ? "per month" : "per month"})`}
                </div>
              )}
            </div>
          </>
        )}
      </header>
      <ul style={type == "free" ? { paddingTop: interval === "monthly" ? 72 : 100 } : {}}>
        {features.map((it, index) => {
          const listImageSource = `/images/${type === "free"
              ? "free-feature-check.svg"
              : type === "personal"
                ? "personal-feature-check.svg"
                : "business-feature-check.svg"
            }`;
          return (
            <li key={index}>
              <img src={listImageSource} alt="Check" />
              <span>{it}</span>
            </li>
          );
        })}
      </ul>
      <footer>
        <CheckoutButton type={type} onClick={onSelect} loading={loading} />
      </footer>
    </div>
  );
};

export default PurchasePlanCard;
