import { request } from "./request";

export async function checkoutAsync(
  type: "personal" | "business",
  interval: "monthly" | "yearly"
): Promise<{ url: string }> {
  const { url } = await request(`/checkout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ 
      plan: type === "personal" ? "personal_v2" : "business_v1", 
      interval,
      success_url: `${window.location.origin}/checkout/success`,
      cancel_url: window.location.href,
    })
  });
  return { url }
}