import { FC, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InstanceContext } from "../../context/InstanceContext";
import { fetchSetupDomainAsync } from "../../services/instance";
import { reserved_domains } from "../../data/reserved_domains";
import { Ring } from "@uiball/loaders";
import styles from "./index.module.css";

const SpecifyDoamin: FC = () => {
  const navigate = useNavigate();

  const [input, setInput] = useState("");

  const [loading, setLoading] = useState(false);

  const { currentInstanceDetails } = useContext(InstanceContext);

  const inputRef = useRef<HTMLInputElement>(null);

  function checkSubdomain(subdomain: string): { valid: false; message: string; } | { valid: true } {
    if (subdomain.length < 4) {
      return {
        valid: false,
        message: "The subdomain should be at least 4 characters."
      }
    }
    if (subdomain.length > 32) {
      return {
        valid: false,
        message: "The subdomain should be less than or equal to 32 characters."
      }
    }
    if (subdomain.startsWith("-") || subdomain.endsWith("-")) {
      return {
        valid: false,
        message: "The subdomain cannot start or end with hyphen(-)"
      }
    }
    const regex = /^[a-z0-9][a-z0-9-]*[a-z0-9]$/;
    if (!subdomain.match(regex)) {
      return {
        valid: false,
        message: "Invalid subdomain format. The subdomain can only contain lower case letters, numbers & hyphen(-)"
      }
    }
    if (reserved_domains.includes(subdomain)) {
      return {
        valid: false,
        message: "This subdomain is reserved & cannot be used."
      }
    }
    return { valid: true }
  }

  async function handleNextClick() {
    if (loading) return;
    const subdomain = input.trim();
    // console.log(`subdomain: ${subdomain}`);
    const checkResult = checkSubdomain(subdomain);
    if (!checkResult.valid) {
      alert(checkResult.message);
      inputRef.current?.focus();
      return;
    }
    if (!window.confirm(`Use domain ${subdomain}.firewalla.net for your MSP portal?`)) {
      return;
    }
    try {
      if (!currentInstanceDetails?.id) {
        throw new Error("No instance id");
      }
      setLoading(true);
      await fetchSetupDomainAsync(currentInstanceDetails.id, subdomain);
      window.location.replace(`${window.location.origin}/checkout/success`);
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  if (currentInstanceDetails?.domain && currentInstanceDetails.domain !== "*") {
    navigate("/");
    return null;
  }

  return (
    <div className={styles["container"]}>
      <img src="/images/specify-domain.svg" alt="specify domain" />
      <h2 className={styles["title"]}>What's the Domain You Want to Use?</h2>
      <div className={styles["input-container"]}>
        <input
          autoFocus
          ref={inputRef}
          type="text"
          value={input}
          placeholder="my-company"
          size={(input || "my-company").length + 1}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              handleNextClick();
            }
          }}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <span style={{ paddingRight: (input || "my-company").length * 4 }}>
          .firewalla.net
        </span>
      </div>
      <p className={styles["description"]}>
        Each MSP Business instance will have its own domain name. This could be
        the name of your company.
      </p>
      <button className={styles["next-button"]} onClick={handleNextClick}>
        {loading ? <Ring color="#fff" size={32} /> : "Next"}
      </button>
    </div>
  );
};

export default SpecifyDoamin;
