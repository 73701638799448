import { FC, CSSProperties, createElement, ReactHTML, useMemo, ReactNode } from 'react'

interface IProps {
  size: "large" | "medium" | "small";
  theme?: "light" | "dark";
  as?: "auto" | keyof ReactHTML;
  children: ReactNode;
  style?: CSSProperties
}

const Title: FC<IProps> = ({ size, theme = "light", as = "auto", style, children }) => {

  const titleStyle: CSSProperties = {
    fontSize: size === "large" ? 48 : size === "medium" ? 32 : 26,
    fontWeight: 700,
    color: theme === "light" ? "#333" : "#fff",
    lineHeight: 1.2,
    ...style
  }

  const tag: keyof ReactHTML = useMemo(() => {
    if (as === "auto") {
      return size === "large" ? "h1" : size === "medium" ? "h2" : "h3"
    } else {
      return as;
    }
  }, [as, size])

  return createElement(tag, { style: titleStyle, children })
}

export default Title;