import { FC, CSSProperties } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./index.module.css";

interface IProps {
  type: "free" | "personal" | "business";
  features: string[];
  style?: CSSProperties;
}

const DisplayPlanCard: FC<IProps> = ({ type, features, style }) => {
  const navigate = useNavigate();
  const planTitle =
    type === "free" ? "Free" : type === "personal" ? "Professional" : "Business";
  const imageSource = `/images/${
    type === "free"
      ? "free-plan.svg"
      : type === "personal"
      ? "personal-plan.svg"
      : "business-plan.svg"
  }`;
  const checkImageSource = `/images/${
    type === "free"
      ? "free-feature-check.svg"
      : type === "personal"
      ? "personal-feature-check.svg"
      : "business-feature-check.svg"
  }`;
  return (
    <div className={styles["plan-card"]} style={style} onClick={() => { navigate("/plans") }}>
      <header>
        <h3 className={styles["plan-title"]}>{planTitle}</h3>
        <img src={imageSource} alt="Plan" />
      </header>
      <ol>
        {features.map((feature, index) => {
          return (
            <li key={index}>
              <img src={checkImageSource} alt="Check" />
              <span>{feature}</span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default DisplayPlanCard;
