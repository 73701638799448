import { CSSProperties, FC } from "react";
import classNames from "classnames";
import useWindowSize from "../../hooks/useWindowSize";
import { IFeatureIntroInfo } from "./interface";
import Title from "../Title";
import styles from "./index.module.css";

interface IProps extends IFeatureIntroInfo {
  order?: "image-text" | "text-image";
  style?: CSSProperties
}

const FeatureIntro: FC<IProps> = ({
  imageSource,
  title,
  descriptions,
  order = "image-text",
  style
}) => {
  const HIHG_ORDER = 1;
  const LOW_ORDER = 2;
  const { width } = useWindowSize();
  const isMobile = width < 768;
  return (
    <div
      className={classNames(styles["feature-intro"], {
        [styles["feature-intro-mobile"]]: isMobile,
      })}
      style={style}
    >
      <div
        className={classNames(styles["half"], styles["feature-image"])}
        style={{
          order: isMobile
            ? LOW_ORDER
            : order === "image-text"
            ? HIHG_ORDER
            : LOW_ORDER,
        }}
      >
        <img src={imageSource} alt="Feature" />
      </div>
      <div
        className={classNames(styles["half"], styles["feature-text"])}
        style={{
          order: isMobile
            ? HIHG_ORDER
            : order === "image-text"
            ? LOW_ORDER
            : HIHG_ORDER,
        }}
      >
        <div>
          <Title size="small" style={{ marginBottom: 16 }}>
            {title}
          </Title>
          <ul className={styles["feature-description"]}>
            {descriptions.map((it, index) => (<li key={index}>{it}</li>))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeatureIntro;
