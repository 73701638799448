import { IAuthInfo } from '../context/AuthContext';
import { SS_KEY } from '../constants'

// see: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export function parseJwt(token: string)  {
  let result = null;
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    result = JSON.parse(jsonPayload);
  } catch (err) {
    console.error(`Fail to parse jwt token ${token}`, err)
  }
  return result;
};

export function getOAuthPortalAddress(action: "signup" | "login" | "logout"): string {
  // assemble redirect url
  const path = action === "logout" 
    ? "logout" 
    : action === "login"
      ? "login"
      : "signup";
  const searchParams = new URLSearchParams({
    client_id: process.env.REACT_APP_CLIENT_ID || "",
  })
  if (action === "logout") {
    searchParams.set("logout_uri", window.location.origin)
  } else {
    searchParams.set("response_type", "code")
    searchParams.set("redirect_uri", window.location.origin)
  }
  const url = `${process.env.REACT_APP_LOGIN_SERVER}/${path}?${searchParams.toString()}`
  return url;
}

export function gotoOAuthPortal(action: "signup" | "login" | "logout") {
  // udpate redirect back path before login
  const { pathname, search, hash } = window.location;
  if (action === "login" || action === "signup") {
    sessionStorage.setItem(SS_KEY.PATH_BEFORE_SIGNIN, pathname + search + hash)
  }
  // redirect
  const url = getOAuthPortalAddress(action);
  window.location.replace(url)
}


export async function fetchAuthInfoAsync(code: string): Promise<IAuthInfo> {
  const url = `${process.env.REACT_APP_LOGIN_SERVER}/oauth2/token`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams({
      grant_type: "authorization_code",
      redirect_uri: window.location.origin,
      code: code,
      client_id: process.env.REACT_APP_CLIENT_ID || "",
    }),
  };
  
  const res = await fetch(url, options);

  if (res.ok) {
    const { id_token, access_token } = await res.json();
    const email = parseJwt(id_token)?.email; 
    return { email, access_token, id_token }
  }
  throw new Error(res.statusText)
}
