import { FC, useContext } from "react";
import { UIConfigContext } from "../../context/UIConfigContext";
import InfoIcon from './InfoIcon';
import { Tooltip } from 'antd';
import styles from './index.module.css';

// add data-lable attribute so that the table is responsive in phone view
// see: https://github.com/chokcoco/iCSS/issues/187

const FeatureComparisonTable: FC = () => {
  const { config } = useContext(UIConfigContext);

  return (
    <table className={styles["feauture-comparison-table"]}>
      <thead>
        <tr>
          <th></th>
          <th>Free</th>
          <th>Professional</th>
          <th>Business</th>
        </tr>
      </thead>
      <tbody>
        {config?.compare_features.map((feature, index) => {
          // tip
          let htmlString = feature.tip?.message;
          if (feature.tip?.links?.length) {
            feature.tip?.links.forEach(({ keyword, link }) => {
              htmlString = htmlString ? htmlString.replace(keyword, `<a href="${link}" target="_blank" style="text-decoration: none; color: #027BFF">${keyword}</a>`) : undefined;
            })
          }
          const tip = htmlString ? <div dangerouslySetInnerHTML={{ __html: htmlString }}></div> : undefined;

          return (
            <tr key={index}>
              <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {feature.name}
                {
                  tip && 
                  <Tooltip color="#fff" title={tip} placement="bottom" mouseLeaveDelay={0.5}>
                    <i><InfoIcon /></i>
                  </Tooltip>
                }
              </td>
              <td data-label="free">{feature.free.available ? feature.free.desc : "-"}</td>
              <td data-label="personal">
                {feature.personal.available
                  ? feature.personal.desc || (
                      <div>
                        <img
                          src="/images/personal-feature-check.svg"
                          alt="Check"
                        />
                      </div>
                    )
                  : "-"}
              </td>
              <td data-label="business">
                {feature.business.available
                  ? feature.business.desc || (
                      <div>
                        <img
                          src="/images/business-feature-check.svg"
                          alt="Check"
                        />
                      </div>
                    )
                  : "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FeatureComparisonTable;
