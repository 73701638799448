import { FC } from "react";
import classNames from "classnames";
import TextEllipsis from "react-texas";
import Dropdown from "rc-dropdown";
import AccountIcon from "./AccountIcon";
import Menu from "../Menu";
import { gotoOAuthPortal } from "../../services/auth";
import { LS_KEY } from "../../constants";
import SignoutIcon from "./SignoutIcon";
import styles from "./index.module.css";

interface IProps {
  theme: "light" | "dark";
  email: string;
  onlyShowIcon?: boolean;
}

const AccountDropdown: FC<IProps> = ({ theme, email, onlyShowIcon }) => {
  const iconColor = theme === "light" ? "#555" : "#dfdfdf";
  return (
    <Dropdown
      overlay={
        <Menu
          theme={theme}
          items={[
            {
              text: "Sign Out",
              icon: <SignoutIcon color={iconColor} />,
              onClick: () => {
                localStorage.removeItem(LS_KEY.AUTH_INFO);
                gotoOAuthPortal("logout");
              },
            },
          ]}
        />
      }
      trigger={["hover"]}
    >
      <div
        className={classNames(styles["account"], {
          [styles["account-light"]]: theme === "light",
          [styles["account-dark"]]: theme === "dark",
        })}
      >
        <AccountIcon color={iconColor} />
        {!onlyShowIcon && <TextEllipsis>{email}</TextEllipsis>}
      </div>
    </Dropdown>
  );
};

export default AccountDropdown;
