import { createContext } from "react";

export interface IMspInstance {
  id: string;
  domain: string; /* could be '*' if domain is not set for business plan */
  canceled: boolean;
  created: number; // timestamp in ms
}

export interface IMspInstanceDetails extends IMspInstance {
  plan: {
    category: "personal" | "business",
    interval: "monthly" | "yearly",
  },
  initialized: boolean; // when domain is not set for business plan, this will be false
  installed: boolean; // whether the MSP instance is ready to be accessed
  trial: true;
}

export const InstanceContext = createContext<{
  loading: boolean; // loading instances & current instance details
  instances: IMspInstance[];
  deleteInstanceById: (id: string) => void;
  currentInstanceDetails: IMspInstanceDetails | null;
  updateCurrentInstance: (newInstance: IMspInstanceDetails) => void;
}>({
  loading: false,
  instances: [],
  deleteInstanceById: () => { console.warn("not implementated") },
  currentInstanceDetails: null,
  updateCurrentInstance: () => { console.warn("not implementated") }
});

export function getCurrentInstance(instances: IMspInstance[]): IMspInstance | undefined {
  const validInstances = instances.filter(it => it.canceled === false);
  if (validInstances.length === 0) {
    return undefined
  } else if (validInstances.length === 1) {
    return validInstances[0]
  } else {
    // use the lastest instance
    validInstances.sort((a, b) => a.created > b.created ? 1 : -1)
    return validInstances[validInstances.length - 1]
  }
}

