import { FC } from 'react'
import classNames from 'classnames';
import { Ring } from "@uiball/loaders"
import styles from './index.module.css'

interface IProps {
  type: "free" | "personal" | "business";
  loading: boolean;
  onClick: () => void;
}

const CheckoutButton: FC<IProps> = ({ type, loading, onClick }) => {

  return (
    <div
      className={classNames(styles["plan-button"], {
        [styles["free-plan-button"]]: type === "free",
        [styles["personal-plan-button"]]: type === "personal",
        [styles["business-plan-button"]]: type === "business",
      })}
      onClick={onClick}
    >
      {loading ? <Ring color='#fff' size={32} /> : type === "free" ? "my.firewalla.com" : "Get Started"}
    </div>
  )
}

export default CheckoutButton;