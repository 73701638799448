import { FC, useState, useEffect, CSSProperties } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import {
  AuthContext,
  getAuthInfoFromLocalStorage,
  IAuthInfo,
  launchMspPortal,
} from "./context/AuthContext";
import { fetchAuthInfoAsync } from "./services/auth";
import Header from "./components/Header";
import Home from "./pages/Home";
import Plans from "./pages/Plans";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import Account from "./pages/Account";
import SpecifyDomain from "./pages/SpecifyDomain";
import Footer from "./components/Footer";
import {
  IMspInstance,
  IMspInstanceDetails,
  InstanceContext,
  getCurrentInstance,
} from "./context/InstanceContext";
import { ISelectedPlanBeforeSignin, LS_KEY, SS_KEY } from "./constants";
import {
  fetchMspInstanceDetailsAsync,
  fetchMspInstancesAsync,
} from "./services/instance";
import { Ring } from "@uiball/loaders";
import { fetchUIConfig } from "./services/uiconfig";
import { IUIConfig } from "./data/interface";
import fakeUiConfig from "./data/ui-config.json";
import { UIConfigContext } from "./context/UIConfigContext";
import { checkoutAsync } from "./services/checkout";

const App: FC = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  /* state */

  const [uiConfig, setUIConfig] = useState<IUIConfig | null>(null);

  const [authInfo, setAuthInfo] = useState<Partial<IAuthInfo>>(() => {
    return getAuthInfoFromLocalStorage() || {};
  });

  const [instances, setInstances] = useState<IMspInstance[]>([]);

  const [currentInstanceDetails, setCurrentInstanceDetails] =
    useState<IMspInstanceDetails | null>(null);

  const [freshLogin, setFreshLogin] = useState(false);

  /* loading state */

  const [loadingUIConfig, setLoadingUIConfig] = useState(true);

  const [loadingAuthInfo, setLoadingAuthInfo] = useState(Boolean(code));

  const [loadingInstanceInfo, setLoadingInstanceInfo] = useState(Boolean(authInfo.id_token));

  const [redirectingToInstance, setRedirectingToInstance] = useState(false);

  const [loadingCheckoutUrl, setLoadingCheckoutUrl] = useState(false);

  /* computed values */

  const planToCheckout = sessionStorage.getItem(
    SS_KEY.SELECTED_PLAN_BEFORE_SIGNIN
  );

  const loading =
    loadingUIConfig ||
    loadingAuthInfo ||
    loadingInstanceInfo ||
    loadingCheckoutUrl ||
    redirectingToInstance;

  /* effects */

  useEffect(() => {
    // scroll to top after navigation
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingUIConfig(true);
        let config: IUIConfig | null = null;
        if (process.env.NODE_ENV === "development") {
          config = await new Promise<IUIConfig>((resolve) => {
            setTimeout(() => {
              resolve(fakeUiConfig as IUIConfig);
            }, 1000);
          });
        } else {
          config = await fetchUIConfig();
        }
        setUIConfig(config);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingUIConfig(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!code) return;
      try {
        /* load auth info */
        setFreshLogin(true);
        setLoadingAuthInfo(true);
        const authInfo = await fetchAuthInfoAsync(code);
        setAuthInfo(authInfo);
        localStorage.setItem(LS_KEY.AUTH_INFO, JSON.stringify(authInfo));
      } catch (err) {
        console.error(err);
        alert("Failed to fetch auth info.");
      } finally {
        setLoadingAuthInfo(false);
        // remove code from url
        navigate(pathname, { replace: true });
      }
    })();
    // eslint-disable-next-line
  }, [code]);

  useEffect(() => {
    (async function () {
      if (!authInfo.id_token) return;
      try {
        /* load instances */
        setLoadingInstanceInfo(true);
        const instances = await fetchMspInstancesAsync();
        setInstances(instances);
        const curInstance = getCurrentInstance(instances);
        if (curInstance) {
          /* load instance details */
          const instanceDetails = await fetchMspInstanceDetailsAsync(
            curInstance.id
          );
          setCurrentInstanceDetails(instanceDetails);
          /* redirect based on instance status */
          if (pathname === "/account") return; // allow account page for debug purpose
          const { domain, installed } = instanceDetails;
          if (domain === "*") {
            navigate("/specify-domain", { replace: true }); // business plan & doamin is not set
            return;
          }
          if (!installed) {
            navigate("/checkout/success", { replace: true });
            return;
          }
          if (freshLogin) {
            setRedirectingToInstance(true);
            launchMspPortal(domain, "_self")
            return;
          }
        } else {
          setCurrentInstanceDetails(null);
          /* load checkout url & redirect to it */
          if (planToCheckout) {
            const { type, interval, selected_at } = JSON.parse(planToCheckout) as ISelectedPlanBeforeSignin;
            const minutesEllapsed = (Date.now() - selected_at) / 1000 / 60;
            sessionStorage.removeItem(SS_KEY.SELECTED_PLAN_BEFORE_SIGNIN);
            if (minutesEllapsed <= 5) {
              setLoadingCheckoutUrl(true);
              const { url } = await checkoutAsync(type, interval);
              window.location.replace(url);
            } else {
              console.info(`selected plan in local storaged is ignored (minutes ellapsed: ${minutesEllapsed})`)
            }
          }
        }
      } catch (err) {
        console.error(err);
        alert(err);
      } finally {
        setLoadingInstanceInfo(false);
      }
    })();
    // eslint-disable-next-line
  }, [authInfo.id_token]);

  useEffect(() => {
    /* redirect back after fresh login */
    if (loading) return;
    if (!freshLogin) return;
    const redirectPath = sessionStorage.getItem(SS_KEY.PATH_BEFORE_SIGNIN);
    if (redirectPath) {
      sessionStorage.removeItem(SS_KEY.PATH_BEFORE_SIGNIN);
      navigate(redirectPath, { replace: true });
    }
    // eslint-disable-next-line
  }, [loading]);

  /* view */

  const loaderStyle: CSSProperties = {
    height: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 24,
  };

  return (
    <UIConfigContext.Provider
      value={{ loading: loadingUIConfig, config: uiConfig }}
    >
      <AuthContext.Provider value={{ loading: loadingAuthInfo, ...authInfo }}>
        <InstanceContext.Provider
          value={{
            loading: loadingInstanceInfo,
            instances: instances,
            currentInstanceDetails: currentInstanceDetails,
            deleteInstanceById: (id) => {
              setInstances((arr) => arr.filter((it) => it.id !== id));
              if (id === currentInstanceDetails?.id) {
                setCurrentInstanceDetails(null);
              }
            },
            updateCurrentInstance: (newInstance: IMspInstanceDetails) => {
              setCurrentInstanceDetails(newInstance)
            }
          }}
        >
          {loading ? (
            <div style={loaderStyle}>
              <Ring size={60} />
            </div>
          ) : (
            <>
              <Header theme={pathname === "/" ? "dark" : "light"} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/plans" element={<Plans />} />
                {
                  (
                    window.location.host.includes("localhost") ||
                    window.location.host.includes("dd.firewalla") 
                  ) && <Route path="/account" element={<Account />} />
                }
                <Route path="/specify-domain" element={<SpecifyDomain />} />
                <Route path="/checkout/success" element={<CheckoutSuccess />} />
              </Routes>
              {["/", "/plans"].includes(pathname) && <Footer />}
            </>
          )}
        </InstanceContext.Provider>
      </AuthContext.Provider>
    </UIConfigContext.Provider>
  );
};

export default App;
