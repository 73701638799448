import { IUIConfig } from "../data/interface";

export async function fetchUIConfig(): Promise<IUIConfig> {
  const res = await fetch(`${window.location.origin}/data/config.json`, {
    method: "GET",
    headers: {
      "Accept": "application/json"
    },
  });
  if (res.ok) {
    const data = await res.text();
    return JSON.parse(data)
  }
  throw new Error(res.statusText)
}
