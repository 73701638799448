import { LS_KEY } from "../constants"
import { getAuthInfoFromLocalStorage } from "../context/AuthContext";
import { gotoOAuthPortal } from "./auth";

/**
 * wrapper of browser's native fetch method
 */
async function request(path: string, options: RequestInit) {
  const authInfo = getAuthInfoFromLocalStorage();
  if (!authInfo?.id_token) {
    gotoOAuthPortal("login")
    throw new Error("Access Denied. (Reason: no auth token is available)")
  }
  const requestOptions: RequestInit = {
    ...options,
    headers: {
      ...options["headers"],
      "Authorization": `Bearer ${authInfo.id_token}`,
    }
  }
  const response = await fetch(`${process.env.REACT_APP_API_SERVER}${path}`, requestOptions)
  if (response.ok) {
    const data = await response.json()
    return data;
  }
  const errCodeStr = `(code: ${response.status})`;
  if (response.status === 401) {
    localStorage.removeItem(LS_KEY.AUTH_INFO)
    gotoOAuthPortal("login")
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(null)
        throw new Error(`Token expired. ${errCodeStr}`)
      }, 10000);
    })
  }
  const resText = await response.text();
  if (resText && resText.trim()) {
    throw new Error(`${resText.trim()}. ${errCodeStr}`)
  } else {
    throw new Error(`${response.statusText}. ${errCodeStr}`)
  }
}

export { request }