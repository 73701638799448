import { FC } from "react";

interface IProps {
  color?: string;
}

const SignoutIcon: FC<IProps> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.36346 4.36346V19.6362H14.5182V21.818H4.17777C3.11007 21.818 2.18164 20.9249 2.18164 19.8977V4.1019C2.18164 3.07478 3.11007 2.18164 4.17777 2.18164H14.5182V4.36346H4.36346ZM17.9838 13.0907L15.2725 15.8019L16.8153 17.3447L20.6723 13.4878L22.2151 11.945L20.6723 10.4022L16.8153 6.54528L15.2725 8.08806L18.0934 10.9089H9.818V13.0907H17.9838Z"
        fill={color || "#555"}
      />
    </svg>
  );
};

export default SignoutIcon;
