import { FC, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { InstanceContext } from "../../context/InstanceContext";
import { fetchDeleteInstanceAsync } from "../../services/instance";
import styles from "./index.module.css";

const Account: FC = () => {
  const authContext = useContext(AuthContext);

  const instanceContext = useContext(InstanceContext);

  async function handleDeleteInstance(instance_id: string) {
    if (!authContext.id_token) return;
    await fetchDeleteInstanceAsync(instance_id);
    instanceContext.deleteInstanceById(instance_id);
  }

  return (
    <div className={styles["container"]}>
      <h2>Account Details</h2>
      <p>
        <span>Email:</span>
        <span>{authContext.email}</span>
      </p>
      <h2>Active Instance Details</h2>
      {instanceContext.currentInstanceDetails ? (
        <pre>
          {JSON.stringify(instanceContext.currentInstanceDetails, null, 2)}
        </pre>
      ) : (
        <div>No active instance</div>
      )}
      <h2>Instance List</h2>
      {instanceContext.instances.map((it) => {
        return (
          <div key={it.id} style={{ marginBottom: 36 }}>
            <pre>{JSON.stringify(it, null, 2)}</pre>
            {!it.canceled && (
              <button
                style={{ marginTop: 4, cursor: "pointer" }}
                onClick={() => {
                  handleDeleteInstance(it.id);
                }}
              >
                Delete
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Account;
