import { FC, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import FeatureIntro from "../../components/FeatureIntro";
import { UIConfigContext } from "../../context/UIConfigContext";
import DisplayPlanCard from "../../components/PlanCard/DisplayPlanCard";
import Title from "../../components/Title";
import "./index.css";

const Home: FC = () => {
  const navigate = useNavigate();

  const { config } = useContext(UIConfigContext);

  return (
    <>
      <main>
        <section 
          className="call-for-action" 
          style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL + "/images/homepage-bg-map.svg"}), url(${process.env.PUBLIC_URL + "/images/homepage-bg-curve.svg"})` 
          }}  
        >
          <Title
            size="large"
            theme="dark"
            style={{
              marginTop: 160,
              textAlign: "center"
            }}
          >
            {config?.headline || "title"}
          </Title>
          <button
            className="compare-plans-button"
            onClick={() => {
              navigate("/plans");
            }}
          >
            Compare Plans
          </button>
          <iframe
            className="intro-video"
            width={840}
            src={config?.video_embed.src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </section>
        <section className="choose-plan">
          <Title
            size="medium"
            style={{ marginBottom: 48 }}
          >
            Choose Your Plan
          </Title>
          <div className="plans">
            {config?.plans.map((plan) => {
              return (
                <DisplayPlanCard
                  key={plan.id}
                  type={plan.id}
                  features={plan.key_features_desc}
                  style={{ flex: 1, maxWidth: "100%" }}
                />
              );
            })}
          </div>
          <Link to="/plans" className="learn-more">
            <span>Learn More</span>
            <img src="/images/right-arrow.svg" alt="Learn More" />
          </Link>
        </section>
        <section className="features" id="features">
          {config?.key_features.map((feature, index) => {
            return (
              <FeatureIntro
                key={index}
                order={index % 2 === 0 ? "image-text" : "text-image"}
                imageSource={feature.img}
                title={feature.title}
                descriptions={feature.desc}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f5f5f7" : "inherit",
                }}
              />
            );
          })}
        </section>
      </main>
    </>
  );
};

export default Home;
