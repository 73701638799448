import { FC, useContext, useEffect, useState, CSSProperties } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import classNames from "classnames";
import SigninButton from "../SigninButton";
import HamburgerButton from "../HamburgerButton";
import { UIConfigContext } from "../../context/UIConfigContext";
import Dropdown from "rc-dropdown";
import Menu from "../Menu";
import { InstanceContext } from "../../context/InstanceContext";
import LaunchPortalButton from "../LaunchPortalButton";
import { AuthContext } from "../../context/AuthContext";
import AccountDropdown from "../AccountDropdown";
import "rc-dropdown/assets/index.css";
import styles from "./index.module.css";

interface IProps {
  theme: "dark" | "light";
  style?: CSSProperties;
}

const Header: FC<IProps> = ({ theme, style }) => {

  const { width } = useWindowSize();

  const isMobile = width < 1000;

  const isMediumScreen = width < 1200;

  const { config } = useContext(UIConfigContext);

  const { email } = useContext(AuthContext);

  const { currentInstanceDetails } = useContext(InstanceContext);

  const [showMenu, setShowMenu] = useState(isMobile ? false : true);

  useEffect(() => {
    setShowMenu(isMobile ? false : true);
  }, [isMobile]);

  return (
    <header
      className={classNames(styles["site-header"], {
        [styles["site-header-light"]]: theme === "light",
        [styles["site-header-dark"]]: theme === "dark",
      })}
      style={style}
    >
      <div className={styles["content"]}>
        <nav className={isMobile ? styles["mobile"] : styles["desktop"]}>
          <Link to="/">
            <img
              src={
                theme === "dark"
                  ? "/images/site-logo-dark.svg"
                  : "/images/site-logo-light.svg"
              }
              alt="site logo"
              onClick={() => {
                isMobile && setShowMenu(false);
              }}
            />
          </Link>
          {showMenu && (
            <ul
              onClick={() => {
                isMobile && setShowMenu(false);
              }}
            >
              {config?.menus.map((menu, index) => {
                if (!("subMenus" in menu)) {
                  return (
                    <li key={index}>
                      {menu.link.startsWith("/") ? (
                        <Link to={menu.link}>{menu.text}</Link>
                      ) : (
                        <a href={menu.link} target="_blank" rel="noreferrer">
                          {menu.text}
                        </a>
                      )}
                    </li>
                  );
                } else {
                  if (isMobile) return null;
                  return (
                    <li key={index}>
                      <Dropdown
                        trigger={["hover"]}
                        overlay={<Menu theme={theme} items={menu.subMenus} />}
                      >
                        <div>{menu.text}</div>
                      </Dropdown>
                    </li>
                  );
                }
              })}
              {
                isMobile && 
                <div style={{ display: "flex", justifyContent: "center", gap: 16, height: 48 }}>
                  {email ? (
                    <AccountDropdown theme={theme} email={email} />
                  ) : (
                    <SigninButton theme={theme} />
                  )}
                </div>
              }
            </ul>
          )}
        </nav>
        {isMobile ? (
          <HamburgerButton
            theme={theme}
            onClick={() => {
              setShowMenu((val) => !val);
            }}
            menuIsOpen={showMenu}
            style={{
              position: "absolute",
              right: 16,
              zIndex: 2,
            }}
          />
        ) : (
          <div style={{ display: "flex", gap: 16 }}>
            {currentInstanceDetails && <LaunchPortalButton theme={theme} />}
            {email ? (
              <AccountDropdown theme={theme} email={email} onlyShowIcon={isMediumScreen} />
            ) : (
              <SigninButton theme={theme} />
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
