import { FC, useContext } from "react";
import { UIConfigContext } from "../../context/UIConfigContext";
import Title from "../Title";
import styles from "./index.module.css";

const Footer: FC = () => {
  const { config } = useContext(UIConfigContext);
  return (
    <footer className={styles["site-footer"]}>
      {
        (config?.faqs || []).length > 0 && (
          <section className={styles["faq"]}>
            <div className={styles["content"]}>
              <Title
                size="medium"
                theme="dark"
                style={{
                  textAlign: "center",
                  marginBottom: 36,
                }}
              >
                {config?.faq_text || "Frequently Asked Questions"}
              </Title>
              <ul>
                {config?.faqs.map((it, index) => {
                  return (
                    <li key={index}>
                      <div className={styles["question"]}>
                        <img src="/images/dot.svg" alt="dot" />
                        <span>{it.question}</span>
                      </div>
                      <div className={styles["answer"]}>{it.answer}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        )
      }
      <section className={styles["bottom"]}>
        <div className={styles["content"]}>
          <div className={styles["links"]}>
            {config?.footer.map(it => {
              return (
                <a href={it.link} key={it.text}>{it.text}</a>
              )
            })}
          </div>
          <div className={styles["copyright"]}>
            Copyright &copy; {(new Date()).getFullYear()} Firewalla
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
