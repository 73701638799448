import { createContext } from 'react'
import { LS_KEY } from '../constants'

export interface IAuthInfo {
  id_token: string,
  access_token: string,
  email: string,
}

export const AuthContext = createContext<Partial<IAuthInfo> & { loading: boolean }>({ loading: false })

export function getAuthInfoFromLocalStorage(): IAuthInfo | null {
  let info: IAuthInfo | null = null;
  try {
    const authInfoString = localStorage.getItem(LS_KEY.AUTH_INFO);
    if (authInfoString) {
      const value = JSON.parse(authInfoString);
      if ("id_token" in value && "access_token" in value && "email" in value) {
        info = value
      } else {
        console.error("Auth info in localStorage is wrong")
      }
    }
  } catch (err) {
    console.error(err);
  }
  return info;
}

export function launchMspPortal(domain: string, target: "_blank" | "_self") {
  const url = domain.startsWith("http") ? domain : `https://${domain}`;
  window.open(url, target)
}