// local stoarge keys
export const LS_KEY = {
  AUTH_INFO: "AUTH_INFO",
}

// session storage keys
export const SS_KEY = {
  PATH_BEFORE_SIGNIN: "PATH_BEFORE_SIGNIN",
  SELECTED_PLAN_BEFORE_SIGNIN: "SELECTED_PLAN_BEFORE_SIGNIN"
}

export interface ISelectedPlanBeforeSignin {
  selected_at: number; // timestamp in milliseconds
  type: "personal" | "business";
  interval: "monthly" | "yearly";
}