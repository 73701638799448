import { FC, useContext } from "react";
import { UIConfigContext } from "../../context/UIConfigContext";
import Title from "../../components/Title";
import "./index.css";

const SeatsInfo: FC = () => {
  const { config } = useContext(UIConfigContext);

  if (!config?.seats) return null;

  return (
    <section className="seat-type">
      <div className="seat-desc">
        <Title
          size="small"
          style={{ marginBottom: 16 }}
        >
          {config?.seats.title}
        </Title>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          {
            (config?.seats.desc || []).map(({ message, links }) => {
              if (!links?.length) {
                return <div>{message}</div>
              }
              let htmlString = message;
              links.forEach(({ keyword, link }) => {
                htmlString = htmlString.replace(keyword, `<a href="${link}" target="_blank" style="text-decoration: none; color: #027BFF">${keyword}</a>`);
              })
              return <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
            })
          }
        </div>
      </div>
      <table className="seat-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Seat Type</th>
            <th style={{ textAlign: 'left' }}>Available to...</th>
            <th style={{ textAlign: 'right' }}>Monthly Price</th>
            <th style={{ textAlign: 'right' }}>Yearly Price</th>
          </tr>
        </thead>
        <tbody>
          {config?.seats.seats_info.map(({ type, icon, available, monthly_price, yearly_price }, index) => {
            return (
              <tr key={index}>
                <td style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <img src={icon} />
                  {type}
                </td>
                <td>{available}</td>
                <td style={{ textAlign: 'right' }}>{monthly_price}</td>
                <td style={{ textAlign: 'right' }}>{yearly_price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  )
};

export default SeatsInfo;