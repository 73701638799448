import { FC, CSSProperties } from "react";
import classNames from "classnames";
import styles from "./index.module.css";

interface IProps {
  onClick: () => void;
  theme: "light" | "dark";
  menuIsOpen?: boolean;
  style?: CSSProperties;
}

const HamburgerButton: FC<IProps> = ({ onClick, theme, menuIsOpen = false, style }) => {
  return (
    <div
      className={classNames(styles["hamburger-button"], {
        [styles["hamburger-button-open"]]: menuIsOpen,
        [styles["hamburger-button-light"]]: theme === "light",
        [styles["hamburger-button-dark"]]: theme === "dark",
      })}
      onClick={onClick}
      style={style}
    >
      {Array.from({ length: 3 }).map((_, index) => {
        return <div key={index} className={styles["inner"]}></div>;
      })}
    </div>
  );
};

export default HamburgerButton;
