import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, launchMspPortal } from "../../context/AuthContext";
import { InstanceContext } from "../../context/InstanceContext";
import styles from "./index.module.css";

const CheckoutSuccess: FC = () => {
  const navigate = useNavigate();

  const { currentInstanceDetails } = useContext(InstanceContext);

  const authInfo = useContext(AuthContext);

  if (!currentInstanceDetails) {
    return (
      <div style={{ textAlign: "center", fontSize: 20, lineHeight: "100px" }}>
        No instance found.
      </div>
    );
  }

  if (currentInstanceDetails.domain === "*") {
    navigate("/specify-domain", { replace: true });
    return null;
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <h2>
          Congratulations! You are now on the
          <span style={{ margin: "0px 8px" }}>
            {currentInstanceDetails.plan.category === "personal"
              ? "Professional"
              : "Business"}
          </span>
          Plan.
        </h2>
        <h3>
          Your MSP portal
          <span 
            style={{ margin: "0.3em", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              launchMspPortal(currentInstanceDetails.domain, "_blank")
            }}
          >
            {currentInstanceDetails.domain}
          </span>
          will be ready in around 10 mins.
        </h3>
        <img src="/images/checkout-success.svg" alt="checkout success" />
        <p className={styles["more-info"]}>
          We'll send an email to
          <span className={styles["email"]}>{authInfo.email}</span>
          with a link to your account once everything is set up. In the
          meantime, feel free to check out
          <a
            href="https://help.firewalla.com/hc/en-us/articles/4409866753427"
            target="_blank"
            rel="noreferrer"
          >
            our article
          </a>
          on Firewalla MSP's key features and capabilities.
        </p>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
