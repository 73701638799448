import { IMspInstance, IMspInstanceDetails } from '../context/InstanceContext'
import { request } from './request';

export function fetchMspInstancesAsync(): Promise<IMspInstance[]> {
  return request(`/instance/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    }
  });
}

export function fetchMspInstanceDetailsAsync(instance_id: string): Promise<IMspInstanceDetails> {
  return request(`/instance/show`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Firewalla-Msp-Id": instance_id
    }
  })
}

export function fetchDeleteInstanceAsync(instance_id: string) {
  return request(`/instance/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Firewalla-Msp-Id": instance_id
    }
  })
}

export function fetchSetupDomainAsync(instance_id: string, subdomain: string) {
  return request(`/instance/setup_domain`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Firewalla-Msp-Id": instance_id
    },
    body: JSON.stringify({
      domain: subdomain
    })
  })
}
